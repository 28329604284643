import React from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import styles from './styles/Layout.module.scss'
import { Header } from './components'
import Overlay from './Overlay'
import Sidebar from './Sidebar'
import { Auth } from 'modules'
import { ErrorPage } from 'pages'
import { useAuthStore } from 'store'
import { useCookies } from 'react-cookie'
import { Core, Model } from 'core'

const models: Model<any>[] = Object.keys(Core).map((key) => Core[key])

const errors = {
  FORBIDDEN: 'Нет доступа',
  NOT_FOUND: 'Страница не найдена',
}

export default function Layout() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const { user } = useAuthStore()
  const [error, setError] = React.useState<keyof typeof errors>(null)
  const [sidebarOpened, setSidebarOpened] = React.useState(false)

  React.useEffect(() => {
    setError(null)
    function check() {
      const model = models.find(({ url }) => pathname.includes(url))
      if (!model || !user) return

      if (model.url === pathname && !model.capabilities.findAll) {
        return setError('FORBIDDEN')
      }

      if (model.url + '/create' === pathname && !model.capabilities.create) {
        return setError('FORBIDDEN')
      }

      if (model.url + '/update/' + params?.id === pathname && !model.capabilities.update) {
        return setError('FORBIDDEN')
      }
    }

    if (pathname.match('/.*/$')) {
      navigate(pathname.slice(0, -1))
    } else {
      check()
    }
  }, [pathname, user])

  return (
    <React.Fragment>
      <div className={styles.container}>
        {!user && <Auth />}
        {user && (
          <React.Fragment>
            <Sidebar opened={sidebarOpened} />
            <div className={styles.page}>
              <Header toggleSidebar={() => setSidebarOpened(!sidebarOpened)} />
              <div className={styles.content}>{error ? <ErrorPage title={errors[error]} /> : <Outlet />}</div>
            </div>
          </React.Fragment>
        )}
      </div>
      <Overlay />
    </React.Fragment>
  )
}
