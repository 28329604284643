import React, { ReactNode } from 'react'
import Col from './col'
import Row from './row'
import Header from './header'

type PropsType = {
  children: ReactNode
}

export default function Table({ children }: PropsType) {
  return <table>{children}</table>
}

Table.Col = Col
Table.Header = Header
Table.Row = Row
