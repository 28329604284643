import React, { ReactNode } from 'react'
import styles from './table.module.scss'

type PropsType = {
  children: ReactNode
}

export default function Header({ children }: PropsType) {
  return <tr className={styles.header}>{children}</tr>
}
