import Model from 'core/Model'
import Api from 'core/Api'
import { TUserRole, UserRole } from 'core/models/UserRole/UserRole'
import { TPark } from '../Park/Park'

interface PasswordData {
  password: string
  repeatPassword: string
}

export class UserApi<TUser> extends Api<TUser> {
  async updatePassword(id: number, data: PasswordData) {
    return await this.instance.patch(this.url + '/:id/password'.replace(':id', id.toString()), data)
  }

  async salary(params: { from: Date; to: Date; id: number[] }) {
    return await this.instance.get(this.url + '/salary', { params }).then(({ data }) => data)
  }

  async salaryActors() {
    return await this.instance.get(`${this.url}/salary-actors`).then(({ data }) => data)
  }
}

const userSchema = {
  id: NaN,
  roleKey: '',
  avatar: '',
  firstName: '',
  middleName: '',
  lastName: '',
  birthday: '',
  gender: null,
  phone: '',
  telegram: '',
  whatsapp: '',
  password: '',
}

export const User = new Model<TUser, UserApi<TUser>, ['updatePassword']>({
  Api: UserApi,
  associations: [
    {
      model: UserRole,
      as: 'role',
    },
  ],
  url: '/users',
  capabilities: {
    updatePassword: 'patch.users/:id/password',
    salary: 'get.users/salary',
  },
  schema: userSchema,
  labels: {
    archive: 'Пользователи',
    create: 'Добавить пользователя',
    search: 'Поиск по пользователям',
    creating: 'Добавление пользователя',
    updating: 'Редактирование пользователя',
    menuText: 'Пользователи',
  },
})

export type TUser = any &
  Omit<typeof userSchema, 'gender'> & {
    fullName: string
    gender: 'MALE' | 'FEMALE'
    parks?: TPark[]
    role?: TUserRole
  }
