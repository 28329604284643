import React from 'react'
import { Core } from 'core'
import { useAlertStore, useAuthStore } from 'store'

export default function useInit<T>({ model, fetchParams, onInit, id }: UseInitProps<T>) {
  const [formData, setFormData] = React.useState<T>(null)

  const alert = useAlertStore()

  const { api, schema } = Core[model]

  React.useEffect(() => {
    async function init() {
      if (!id) return setFormData(schema)

      alert.loading({ title: 'Данные загружаются...' })

      await api
        .formData(+id)
        .then((data) => {
          setFormData(data)
          onInit && onInit(data)
        })
        .finally(alert.clear)
    }

    init()
  }, [])

  return { id, formData, setFormData }
}

export type UseInitProps<T> = {
  model: keyof typeof Core
  fetchParams?: any
  onInit?: (data: T) => void
  id?: number
}
