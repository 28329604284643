import React, { useEffect, useRef } from 'react'

export default function useCloseOnClickOutside(show: boolean, close: () => void) {
  const $container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function cleanup() {
      console.log('cleanup')

      document.removeEventListener('click', handleClose)
    }

    function handleClose(e: MouseEvent) {
      if ($container.current && !$container.current.contains(e.target as any)) {
        close()
      }
    }

    if (show) {
      document.addEventListener('click', handleClose)
    } else {
      cleanup()
    }

    return cleanup
  }, [show])

  return $container
}
