import React from 'react'
import * as icons from 'assets/svg'

type PropsType = {
  name: keyof typeof icons
}

export default function Icon({ name }: PropsType) {
  const Icon = icons[name]
  return <Icon width={50} height={50} fill={'blue'} />
}
