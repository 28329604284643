import React from 'react'
import styles from './AdminForm.module.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Page } from 'ui'
import { Form } from 'components'
import { Core } from 'core'
import { useDelete } from 'hooks'
import { useInit, UseInitProps } from './hooks'
import { useAlertStore } from 'store'

export default function AdminForm<T>({
  children,
  fetchParams,
  watch,
  onError,
  onInit,
  ...props
}: AdminFormProps<T>) {
  const navigate = useNavigate()
  const alert = useAlertStore()
  const routerParams = useParams()
  const id = props.id || routerParams.id

  const [status, setStatus] = React.useState<Status>(null)
  const [errors, setErrors] = React.useState(null)

  const { api, labels, url } = Core[props.model]

  const { formData, setFormData } = useInit({
    model: props.model,
    fetchParams,
    onInit,
    id: id as number,
  })

  React.useEffect(() => {
    watch?.forEach(({ name, value }) => {
      setFormData((prev) => ({ ...prev, [name]: value }))
    })
  }, watch)

  function onSuccess({ data }) {
    setStatus('COMPLETE')
    setErrors(null)

    if (props.onSuccess) {
      props.onSuccess(data)
    }

    alert.success({
      title: 'Данные сохранены',
      buttons: [
        {
          component: 'button',
          text: 'Закрыть',
          theme: 'CANCEL',
          onClick: () => {
            alert.clear()

            if (!id) {
              navigate([url, 'update', data.id].join('/'))
            }
          },
        },
        {
          component: 'button',
          text: 'Вернуться назад',
          onClick: () => {
            alert.clear()
            navigate(url)
          },
        },
      ],
    })
  }

  function handleError({ response }) {
    setStatus('ERROR')

    const error = response.data

    if (error.errors) {
      setErrors(error.errors)
    }

    if (onError) {
      onError(response.data)
    }
  }

  function handleCancel() {
    navigate(url)
  }

  const handleDelete = useDelete(props.model)

  async function handleSave() {
    setStatus('SAVING')

    if (id) {
      return await api.update(+id, formData).then(onSuccess).catch(handleError)
    }

    await api.create(formData).then(onSuccess).catch(handleError)
  }

  return (
    <Page>
      <Page.Header title={id ? labels.updating : labels.creating} />
      <div className={styles.container}>
        <Form errors={errors} init={id ? true : false} formData={formData} setFormData={setFormData}>
          {children}
        </Form>
        <div className={styles.footer}>
          <div className={styles.footer__left}>
            {id && (
              <Button component="button" text="Удалить" theme="DANGER" onClick={() => handleDelete(+id)} />
            )}
          </div>
          <div className={styles.footer__right}>
            <Button component="button" text="Назад" theme="CANCEL" onClick={handleCancel} />
            <Button
              component="button"
              text={status === 'SAVING' ? 'Сохранение' : 'Сохранить'}
              theme="PRIMARY"
              fetching={status === 'SAVING'}
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Page>
  )
}

type Status = 'SAVING' | 'COMPLETE' | 'ERROR'

type AdminFormProps<T> = {
  children: React.ReactNode
  watch?: { name: string; value: any }[]
  onError?: (props: any) => void
  onSuccess?: (formData: T) => void
} & UseInitProps<T> & { id?: number }
