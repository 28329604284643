import React, { ReactNode } from 'react'
import styles from './dropdown.module.scss'

type PropsType = {
  children: ReactNode
}

export default function DropdownOption({ children, ...props }: PropsType) {
  console.log(props, 1)

  return (
    <div className={styles.item} {...props}>
      {children}
    </div>
  )
}
