import React, { ReactNode } from 'react'

type ConditionalRenderProps = {
  children: ReactNode
  condition: boolean
  fallback?: ReactNode
}

export default function ConditionalRender({ children, condition, fallback }: ConditionalRenderProps) {
  return <>{condition ? children : fallback}</>
}
