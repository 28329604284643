import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/v2',
  // withCredentials: true,
  // xsrfCookieName: 'csrftoken',
  // xsrfHeaderName: 'X-CSRFToken',
})

instance.interceptors.response.use(
  function (response) {
    if (response.headers.token) {
      document.cookie = `token=${response.headers.token}`
    }

    return response
  },
  function (error) {
    const message = error.response?.data?.message

    alert(message || 'Непредвиденная ошибка')

    return Promise.reject(error)
  }
)

instance.interceptors.request.use((req) => {
  const token = document.cookie.match(/token=(.+?)(;|$)/)[1]
  console.log(token)

  if (token) {
    req.headers.authorization = `Bearer ${token}`
  }

  return req
})

export default instance
