import React from 'react'
import { flattenObject } from 'utils'
import { Catalog } from './components'
import { CatalogProps } from './components/Catalog/types'
import { FormContext } from './Form'

export default function ContainerCatalog<T>({ name, ...props }: ContainerCatalogProps<T>) {
  const { formData, setFormData, errors } = React.useContext(FormContext)
  const state = flattenObject.get(name, formData)

  function update(data: any) {
    if (Array.isArray(data)) {
      // Для модальной формы праздников
      props.onChange?.(data.at(-1))

      setFormData((prev: any) => flattenObject.set(name, data, prev))
    } else {
      setFormData((prev: any) => {
        return flattenObject.set(
          name,
          flattenObject.get(name, prev)?.filter(({ id }) => id !== data.id) || [],
          prev
        )
      })
    }
  }

  return (
    <Catalog<T>
      {...props}
      name={name}
      onChange={update}
      state={state}
      error={errors?.find(({ param }) => param === name)?.msg}
    />
  )
}

type ContainerCatalogProps<T> = Omit<CatalogProps<T>, 'onChange' | 'state' | 'error'> & {
  onChange?: (data: any) => void
}
