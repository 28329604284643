import React, { Fragment } from 'react'
import { Button } from 'ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './Catalog.module.scss'
import { CatalogProps } from './types'
import classNames from 'classnames'
import Modal from './Modal'
import { useModalStore } from 'store'
import { log } from 'console'

export const CatalogContext = React.createContext(null)

export default function Catalog<T>({
  archive,
  buttons,
  className,
  name,
  formCard,
  state,
  onChange,
  error,
}: CatalogProps<T>) {
  const modal = useModalStore()

  function openCatalog() {
    modal.open(<Modal initial={state} archive={archive} onChange={onChange} />)
  }

  return (
    <div className={classNames(className, styles.container)}>
      {/* <Swiper slidesPerView="auto" spaceBetween={10} style={{ width: '100%' }}>
        {state?.map((card, i) => (
          <SwiperSlide key={i} className={styles.card}>
            {formCard({ ...card, onChange, name })}
          </SwiperSlide>
        ))}
      </Swiper> */}
      <div style={{ overflowX: 'auto', display: 'flex', flexWrap: 'nowrap', width: '100%', gap: 10 }}>
        {state?.map((card, i) => (
          <div key={i} className={styles.card}>
            {formCard({ ...card, onChange, name })}
          </div>
        ))}
      </div>
      {error && <div style={{ color: 'var(--danger)' }}>{error}</div>}
      <div className={styles.buttons}>
        {buttons ? (
          buttons(openCatalog).map((button, i) => <Button key={i} component="div" {...button} />)
        ) : (
          <Button component="div" text="Добавить" onClick={openCatalog} theme="OUTLINE" />
        )}
      </div>
    </div>
  )
}
