import Api from 'core/Api'
import Model from 'core/Model'
import { TParkCategory } from '../ParkCategory/ParkCategory'
import { TParty } from '../Party/Party'

const parkSchema = {
  id: NaN,
  title: '',
  inn: '',
  ogrn: '',
  gallery: [],
  comment: '',
  categoryKey: '',
  color: '',
}

class ParkApi<TPark> extends Api<TPark> {
  async report(params: { from: Date; to: Date; id: number[]; internal: boolean; with_profit: boolean }) {
    const url = [this.url, 'report'].join('/')
    return await this.instance.get(url, { params }).then(({ data }) => data)
  }
}

export const Park = new Model<TPark, ParkApi<TPark>>({
  Api: ParkApi,
  url: '/parks',
  schema: parkSchema,
  labels: {
    archive: 'Парки',
    create: 'Добавить парк',
    search: 'Поиск по паркам',
    creating: 'Добавление парка',
    updating: 'Редактирование парка',
    menuText: 'Парки',
  },
  capabilities: {
    report: 'get.parks/report',
  },
})

export type TPark = typeof parkSchema & {
  category?: TParkCategory
  parties: TParty[]
}
