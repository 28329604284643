import React, { ReactNode } from 'react'
import styles from './dropdown.module.scss'
import classNames from 'classnames'
import DropdownOption from './dropdown-option'

type PropsType = {
  children: ReactNode
  className?: string
}

export default function Dropdown({ children, className }: PropsType) {
  return <div className={classNames(styles.container, className)}>{children}</div>
}

Dropdown.Option = DropdownOption
