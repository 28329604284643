import Model from 'core/Model'
import { TPark } from '../Park/Park'
import { TPartyProgram } from '../PartyProgram/PartyProgram'

const partySchema = {
  id: NaN,
  title: '',
  parkId: NaN,
  additionally: [],
  cost: null,
  date: null,
  persons: NaN,
  comment: '',
}

export const Party = new Model<TParty>({
  url: '/parties',
  schema: partySchema,
  labels: {
    archive: 'Праздники',
    create: 'Добавить праздник',
    delete: 'Подтвердите удаление праздника',
    onDelete: 'Праздник удален',
    search: 'Поиск по праздникам',
    creating: 'Добавление праздника',
    updating: 'Редактирование праздника',
    menuText: 'Праздники',
  },
})

export type TParty = Omit<typeof partySchema, 'additionally' | 'date' | 'cost'> & {
  additionally: {
    title: string
    cost: number
  }
  is_internal: boolean
  cost: number
  date: Date
  dateString: string
  park?: TPark
  programs?: TPartyProgram[]
}
