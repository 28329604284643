import React from 'react'
import { ArrayRender } from 'shared/components/tools'
import { Table } from 'shared/components/ui'
import { SettingsDropdown } from 'shared/components/widgets'

export default function ParkCategoriesPage() {
  return (
    <div style={{ padding: 40 }}>
      <Table>
        <Table.Header>
          <Table.Col>Название категории</Table.Col>
          <Table.Col>{}</Table.Col>
        </Table.Header>
        <ArrayRender array={[1, 2]}>
          {(category) => {
            return (
              <Table.Row>
                <Table.Col>{category}</Table.Col>
                <Table.Col fitContent>
                  <SettingsDropdown>
                    <SettingsDropdown.Option>1</SettingsDropdown.Option>
                  </SettingsDropdown>
                </Table.Col>
              </Table.Row>
            )
          }}
        </ArrayRender>
      </Table>
    </div>
  )
}
