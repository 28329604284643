import React from 'react'
import classNames from 'classnames'
import styles from './button.module.scss'

export default React.forwardRef(function Button<C extends ButtonRoots = 'button'>(
  { as: Component = 'button', children, className, size, variant = 'primary', ...props }: ButtonProps<C>,
  ref?: React.RefObject<HTMLElement>
) {
  const cn = classNames(
    styles.button,
    styles[`button_${variant}`],
    size && styles[`button_${size}`],
    className
  )

  return (
    <Component className={cn} ref={ref} {...props}>
      <div className={styles.inner}>{children}</div>
    </Component>
  )
})

export type ButtonRoots = 'div' | 'button' | React.ComponentType<any>

export type BaseProps<C extends ButtonRoots = 'button'> = {
  as?: C
  className?: string
  style?: React.CSSProperties
  variant?: 'primary' | 'light' | 'danger' | 'outline' | 'cancel' | 'success' | 'stroke'
  size?: string
} & React.Attributes

export type ButtonProps<C extends ButtonRoots = 'button'> = C extends keyof JSX.IntrinsicElements
  ? Omit<React.ComponentPropsWithRef<C>, keyof BaseProps<C>> & BaseProps<C>
  : C extends React.ComponentType<infer P>
  ? P extends React.ComponentPropsWithRef<any>
    ? Omit<P, keyof BaseProps<C>> & BaseProps<C>
    : never
  : never
