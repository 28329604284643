import React from 'react'
import styles from './table.module.scss'

type PropsType = {
  children: React.ReactNode
  fitContent?: boolean
} & React.ComponentPropsWithoutRef<'td'>

export default function Col({ children, fitContent = false, ...props }: PropsType) {
  return (
    <td width={fitContent ? 1 : undefined} {...props} className={styles.col}>
      {children}
    </td>
  )
}
