import { Fragment, Key, ReactNode } from 'react'

type ArrayRenderProps<T> = {
  array: T[]
  children: (item: T, i: number) => ReactNode
  setKey?: (item: T, i: number) => Key
  join?: ReactNode | ((item: T, i: number) => ReactNode)
}

export default function ArrayRender<T>({ array, children: render, setKey, join }: ArrayRenderProps<T>) {
  return (
    <>
      {array.map((item, i) => {
        return (
          <Fragment key={setKey?.(item, i) || i}>
            {join && i > 0 && (typeof join === 'function' ? join?.(item, i) : join)}
            {render(item, i)}
          </Fragment>
        )
      })}
    </>
  )
}
