import React, { ReactNode } from 'react'
import { Button, Dropdown, Icon } from 'shared/components/ui'
import { useToggler } from 'shared/hooks'
import { SettingsDropdownContext } from './context'
import { useCloseOnClickOutside } from './hooks'
import SettingsOption from './settings-option'

type PropsType = {
  children: ReactNode
}

export default function SettingsDropdown({ children }: PropsType) {
  const [show, toggle] = useToggler()

  const $container = useCloseOnClickOutside(show, () => toggle(false))

  return (
    <div>
      <Button ref={$container} variant={'cancel'} onClick={() => toggle()}>
        <Icon name={'settings'} />
      </Button>
      {show && (
        <Dropdown>
          <SettingsDropdownContext.Provider value={() => toggle(false)}>
            {children}
          </SettingsDropdownContext.Provider>
        </Dropdown>
      )}
    </div>
  )
}

SettingsDropdown.Option = SettingsOption
